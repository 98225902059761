<style lang="scss">
@import 'scss/main';
</style>
<template>
  <search-wrapper
    :cludo-customer-id="2541"
    :cludo-engine-id="cludoEngineId"
    :initially-load-results="true"
    :key="studyLevel"
    :per-page="10"
    :search-url="searchUrl"
    :searchPayload.prop="searchPayload"
    ref="searchWrapper"
    query-stats-enabled="false"
  >
    <!-- Search Form -->
    <search-form>
      <div class="grid grid--33-66 grid--align-stretch">
        <label for="study-level" class="sr-only">Study level</label>
        <select
          v-model="studyLevel"
          id="study-level"
          name="StudyLevel"
          class="form-control form-control--large"
        >
          <option value="undergraduate">Undergraduate</option>
          <option value="postgraduate_taught">Postgraduate taught</option>
          <option value="postgraduate_research">Postgraduate research</option>
        </select>
        <div class="form-control form-control--large form-control--search">
          <div
            :class="[
              'search-dropdown',
              showDropdown ? 'search-dropdown--results' : '',
            ]"
          >
            <search-input
              :inputName.prop="inputName"
              :search-as-you-type="true"
              placeholder="Course name, keyword or UCAS Code..."
              search-dropdown="results"
            ></search-input>
            <search-results-dropdown
              v-if="searchTerm.length > 3"
              ref="search-results-dropdown"
              :searchResultRenderer.prop="searchResultRenderer"
            ></search-results-dropdown>
          </div>
          <button class="button button--outline bg-white" type="submit">
            <span class="sr-only">Search</span>
            <i class="icon-search" aria-hidden="true"></i>
          </button>
        </div>
        <input
          v-if="studyType"
          type="hidden"
          name="filterStudyType"
          :value="studyType"
        />
      </div>
    </search-form>
  </search-wrapper>
</template>
<script>
import { createApp } from 'vue';
import SearchResult from './components/SearchResult.vue';

export default {
  name: 'App',
  data() {
    return {
      loadingResults: false,
      resultsCount: 0,
      searchTerm: '',
      studyLevel: 'undergraduate',
    };
  },
  props: {
    undergraduateUrl: {
      type: String,
      default: window.location.href,
    },
    postgraduateUrl: {
      type: String,
      default: window.location.href,
    },
    studyYear: {
      type: String,
      default: '',
    },
  },
  computed: {
    /*
     * Returns whether to show the search results dropdown
     *
     * @returns {boolean}
     */
    showDropdown() {
      return this.resultsCount > 0 && this.searchTerm.length > 3;
    },

    /*
     * Returns cludo engine id based on the study level
     *
     * @returns {string}
     */
    cludoEngineId() {
      if (this.studyLevel == 'undergraduate') {
        return 12211;
      }

      return 11278;
    },

    /*
     * Returns the study type based on the study level
     *
     * @returns {string}
     */
    studyType() {
      if (
        this.studyLevel == 'postgraduate_taught' ||
        this.studyLevel == 'postgraduate_research'
      ) {
        return this.studyLevel == 'postgraduate_taught' ? 'Taught' : 'Research';
      }
      return null;
    },

    /*
     * Returns the input name based on the study level
     *
     * @returns {string}
     */
    inputName() {
      return 'query';
    },

    /*
     * Returns the current window location pathname
     *
     * @returns {string}
     */
    searchUrl() {
      let ammendedPostgradUrl = this.postgraduateUrl;
      if(this.studyLevel){
        ammendedPostgradUrl = this.postgraduateUrl + '?filterStudyType=' + this.studyLevel;
      }
      return this.studyLevel == 'undergraduate'
        ? this.undergraduateUrl 
        : ammendedPostgradUrl;
    },

    studyYearFilters() {
      if (this.studyYear) {
        return {
          StudyYear: [this.studyYear],
        };
      }

      return {};
    },

  studyLevelFilters() {
    switch (this.studyLevel) {
      case 'undergraduate':
        return { StudyLevel: ['Undergraduate'] };
      case 'postgraduate_taught':
        return { StudyLevel: ['Postgraduate'], StudyType: ['Taught'] };
      case 'postgraduate_research':
        return { StudyLevel: ['Postgraduate'], StudyType: ['Research'] };
      default:
        return {};
    }
  },



    /*
     * Returns the search payload to provide filters that are executed with the query
     *
     * @returns {string}
     */
    searchPayload() {
      return {
        filters: {
          Category: ['Course'],
          ...this.studyLevelFilters,
          ...this.studyYearFilters,
        },
      };
    },
  },
  methods: {
    /*
     * Overrides the default search result renderer by compiling a local Vue template
     *
     * @returns {string}
     */
    searchResultRenderer(cludoSearchResult) {
      return createApp(SearchResult, {
        searchResult: cludoSearchResult,
      }).mount(document.createElement('div')).$el.outerHTML;
    },
  },
  mounted() {   
    // Listen for `searchTerm` events and update local component data
    this.$refs.searchWrapper.addEventListener('searchTerm', (event) => {
      this.searchTerm = event.detail;
    });

    // Listen for `searchResponse` events and update local component data
    this.$refs.searchWrapper.addEventListener('searchResponse', (event) => {
      this.resultsCount = event.detail.TotalDocument;
    });

    // Listen for `selectedSearchResult` events and update local component data
    this.$refs.searchWrapper.addEventListener(
      'selectedSearchResult',
      (event) => {
        const searchResultsDropdown =
          this.$refs['search-results-dropdown'].firstChild;
        const selectedSearchResult = this.$refs[
          'search-results-dropdown'
        ].querySelector(`#result-${event.detail}`);

        if (selectedSearchResult) {
          // Set scroll top so search result is "in the middle" of the dropdown
          searchResultsDropdown.scrollTop =
            selectedSearchResult.offsetTop -
            searchResultsDropdown.clientHeight / 2 +
            selectedSearchResult.clientHeight / 2;
        } else {
          searchResultsDropdown.scrollTop = 0;
        }
      }
    );
  },
};
</script>
